import React from "react"
import OptimizedImage from "../../utils/optimizedImage"
import { PDFEmbedThumbnailWrapper } from "../../templates/styles/guideDetail.styled"

const PDFEmbedViewer = ({ featuredImage, onShowPdfViewer, pdfLoaded }) => (
  <PDFEmbedThumbnailWrapper>
    {featuredImage && (
      <OptimizedImage
        image={featuredImage?.gatsbyImageData}
        src={featuredImage?.file?.url}
        alt=""
      />
    )}
    {pdfLoaded ? (
      <button className="show-button" onClick={onShowPdfViewer}>
        <img src="/icons/maximize.svg" alt="" />
      </button>
    ) : (
      <p className="loading-text">Loading pdf...</p>
    )}
  </PDFEmbedThumbnailWrapper>
)

export default PDFEmbedViewer
