import { Document, Page, pdfjs } from "react-pdf"
import React from "react"
import {
  PDFViewerMain,
  PDFWrapper,
} from "../../templates/styles/guideDetail.styled"

import closeIcon from "../../assets/icons/close.svg"

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
}

if (pdfjs?.GlobalWorkerOptions) {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
  ).toString()
}

const PDFEmbedViewer = ({
  show,
  externalUrl,
  numPages,
  pageNumber,
  onPreviousPage,
  onNextPage,
  onDocumentLoadSuccess,
  onClosePdfViewer,
}) => (
  <PDFViewerMain show={show}>
    <PDFWrapper>
      <button className="close-button" onClick={onClosePdfViewer}>
        <img src={closeIcon} alt="" />
      </button>
      <Document
        file={externalUrl}
        className="pdf-viewer"
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            className={pageNumber === index + 1 ? "active" : "in-active"}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
      <div className="page-details">
        <p className="page-numbers">
          Page {pageNumber || (numPages ? 1 : "--")} of&nbsp;
          {numPages || "--"}
        </p>
        <div className="button-wrapper">
          <button
            type="button"
            disabled={pageNumber <= 1}
            className="prev"
            onClick={onPreviousPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            className="next"
            onClick={onNextPage}
          >
            Next
          </button>
        </div>
      </div>
    </PDFWrapper>
  </PDFViewerMain>
)

export default PDFEmbedViewer
